@use '../../../public/style/global';

.workspaceContainer {
  @include global.genericPadding();

  .headWrapper {
    @include global.headWrapper();
  }

  .key {
    max-width: 25ch;
    margin-top: 1em;
    text-align: center;
    border: solid thin rgba(0, 0, 0, 0.25);

    .activeKey {
      background-color: #e0f0e3;
    }

    .notInKey {
      background-color: #f8d0d7;
    }
  }
}
