@use '../../../public/style/global';

.formWrapper {
  max-width: 400px;
}

.profileWrapper {
  @include global.genericPadding();
}

.headWrapper {
  @include global.headWrapper;
}

.picture {
  width: 100px;
  height: auto;
  margin: 1rem;
}

.inputWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  :global(.selected-flag) {
    display: flex !important;
    align-items: center;
    justify-content: space-around;
  }

  :global(.selected-dial-code) {
    padding-left: 10px !important;
  }
}

.emailInput,
.phoneNumberInput {
  flex-grow: 1;
  width: auto !important;
}

.verification {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 60px;
  height: 100%;
  margin-left: 1rem;
  padding: 0;
  text-align: center;
  background: none;
  border: unset;

  &.verified {
    color: #008000;
  }

  &.unverified {
    color: #f00;
    cursor: pointer;

    &:disabled {
      color: #808080;
      cursor: not-allowed;
    }
  }
}

.inputInvalidMessage {
  flex-basis: 100%;
  color: #f00;
}

.editAttributeBtn {
  margin: 0 0.5rem;
}
