@import '../../public/style/global';
@import '~@fortawesome/fontawesome-free/scss/solid';

.adminWrapper {
  position: relative;
  display: flex;
  flex: 1 1 100%;
  width: 100%;
  overflow: hidden;
  font-weight: 400;
  font-size: 16px;
}

.adminContent {
  flex: 1 1 100%;
  min-width: $contentMinWidth; // Forces content to be pushed off screen rather than be squished by expanding context bar
  max-width: 100vw;
  overflow: auto;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
}

.devikaFooter {
  display: flex;
  justify-content: center;
  width: 100%;
  height: $footerHeight;
  overflow: hidden;
  background-color: rgb(0, 0, 0);

  a {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 16px;
      object-fit: contain;
    }

    &:hover {
      opacity: 0.75;
    }

    &:focus {
      outline: none;
    }
  }
}

.errorRoute {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  .emphasis {
    position: relative;
    font-weight: 700;
    font-size: 2em;

    &::before {
      position: absolute;
      top: -100vh;
      left: -100vw;
      font-size: 0.5em;
      font-family: 'Font Awesome 5 Free', serif;
      animation: crow 3s ease-in-out 10s 1;
      animation-fill-mode: forwards;
      content: '\f520';
    }

    &::after {
      position: absolute;
      top: #{random(200)}px;
      left: -100vw;
      font-size: 0.5em;
      font-family: 'Font Awesome 5 Free', serif;
      transform: rotate(45deg);
      animation: space 25s ease-in-out 20s infinite;
      animation-fill-mode: forwards;
      content: '\f135';
    }
  }
}

@keyframes space {
  0% {
    left: -100vw;
    transform: rotate(45deg);
  }

  50% {
    left: 100vw;
    transform: rotate(45deg);
  }

  51% {
    transform: rotate(-135deg);
  }

  100% {
    left: -100vw;
    transform: rotate(-135deg);
  }
}

@keyframes crow {
  from {
    top: -100vh;
    left: -100vw;
  }

  to {
    top: -0.3em;
    left: 0;
  }
}
