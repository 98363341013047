@use 'global';

:global(.form-group) {
  margin-bottom: 30px;

  label {
    margin-bottom: 10px;
    font-weight: 600;
  }

  input,
  textarea {
    padding: 10px 15px;
    border-radius: 10px;
  }

  input:not([type='radio']):not([type='checkbox']) {
    height: 50px;
  }

  :global(.form-check-input) {
    position: initial;
    margin: 0 10px 0 0;
  }
}
