@mixin button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  padding: 0.5rem;
  background: unset;
  border: thin solid #ccc;
  box-shadow: inset -2px 0 10px 0 rgba(50, 50, 50, 0.1);

  .picture {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.imageUploadLabel {
  width: 100%;
}

.imageUploadButton {
  @include button();
  width: 100%;
  height: 250px;
}

.toggleUploadButton {
  @include button();
  width: 175px;
  height: 175px;
}

.toggleUploadButtonGrow {
  @include button();
  width: 100%;
  height: 250px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.spinner {
  animation: spin infinite 1s;
}

.imageUploadBody {
  .imageDropzone {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 200px;
    padding: 1em;
    background-color: #fafafa;
    border-radius: 5px;

    img {
      max-width: 100%;
      cursor: pointer;
    }
  }
}
