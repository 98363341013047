.toastAlertWrapper {
  position: fixed;
  bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  .toastAlert {
    position: relative;
    width: max-content;
    min-width: 200px;
    max-width: 100vw;
  }
}
