@use '../../../public/style/global';

.menu {
  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px 15px;
    overflow: hidden;
    background: none !important;
    border: 0;
    border-radius: unset;

    // https://gist.github.com/ayamflow/b602ab436ac9f05660d9c15190f4fd7b
    mask-image: radial-gradient(white, black);

    &::after {
      display: none !important;
    }

    &:hover {
      overflow: hidden;

      // https://gist.github.com/ayamflow/b602ab436ac9f05660d9c15190f4fd7b
      mask-image: radial-gradient(white, black);

      .picture {
        overflow: hidden;

        // https://gist.github.com/ayamflow/b602ab436ac9f05660d9c15190f4fd7b
        mask-image: radial-gradient(white, black);

        img {
          overflow: hidden;
          transform: scale3d(1.2, 1.2, 1.2);

          // https://gist.github.com/ayamflow/b602ab436ac9f05660d9c15190f4fd7b
          mask-image: radial-gradient(white, black);
        }
      }
    }
  }

  .dropdownItem {
    display: flex;
    align-items: center;

    .icon {
      display: flex;
      width: 1rem !important;
      height: 1rem !important;
      margin-right: 8px;

      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.picture {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  width: global.$avatarWidth;
  height: global.$avatarWidth;
  overflow: hidden;
  background: #fafafa;
  border: 3px solid #fafafa;
  border-radius: 50%;

  > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: 0.2s linear;
  }
}
