@use '../../../public/style/global';

.userPayments {
  @include global.genericPadding();
}

.headWrapper {
  @include global.headWrapper;
}

.picture {
  width: 100px;
  height: auto;
  margin: 1rem;
}

.workspaceIcon {
  background: unset;
  border: unset;
}
