@use '../../../../public/style/global';

.workspacePayments {
  @include global.genericPadding();
}

.headWrapper {
  @include global.headWrapper;
}

.stripeLogo {
  display: inline-block;
  width: 90px;
  height: auto;
  vertical-align: bottom;
}
