@use '../../../public/style/global';

.popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  color: white;
  background-color: #0009;

  .close {
    position: absolute;
    top: 40px;
    right: 40px;
    color: white;
    font-size: 32px;
    line-height: 1em;
    background: 0;
    border: 0;
    opacity: 0.65;

    .icon {
      * {
        color: white;
      }
    }
  }

  :global(.slick-slider) {
    width: calc(100% - 100px); // Possibly change to flex col stretch
    max-height: 80vh;
    margin: 50px;
    padding: 20px;

    :global(.slick-track) {
      display: flex;
      max-height: calc(80vh - 40px);

      :global(.slick-slide) {
        display: flex;
        align-items: center;
        justify-content: center;
        height: auto;

        > div {
          width: 100%;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }

    :global(.slick-dots) {
      width: calc(100% - 40px);

      li {
        margin: 0 10px;

        button::before {
          color: white;
          font-size: 16px;
        }
      }
    }
  }
}
