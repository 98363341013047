@use '../../../public/style/global';

.header {
  display: flex;
  align-items: center;
  height: global.$sidebarHeaderHeight;
  margin-bottom: 10px;
  padding: 8px;
  border-radius: 5px;
}

.items {
  .item {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    height: global.$sidebarItemHeight;
    color: #111;
    text-decoration: none;
    border-radius: 8px;

    &.selected,
    &:hover {
      text-decoration: none !important;
      background-color: #0001 !important;
    }

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;

      svg {
        width: 16px;
        height: 16px;
        color: #111;
      }
    }

    &:not(:last-of-type) {
      margin-bottom: 10px;
    }
  }
}

.toolbar {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  grid-area: toolbar;
  justify-content: space-between;
  width: global.$toolbarWidth;
  padding: 20px 0;
  background-color: #e1e1e1;

  .upper {
    img {
      width: 100%;
      height: 100%;
      padding: 4px;
      border-radius: 10px;
      opacity: 1;
      object-fit: cover;
    }

    .hamburger {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding: 0 8px;
      color: inherit;
      background-color: unset;
      border: unset;
      border-radius: 10px;
      opacity: 1;

      &:hover {
        text-decoration: none !important;
        background-color: #0001 !important;
      }
    }

    .items {
      display: flex;
      flex-direction: column;
      align-items: center;

      .item {
        justify-content: center;
        width: 40px;

        button {
          width: 40px;
          height: 40px;
          margin: 0;
          padding: 0;
          background-color: transparent;
          border: 0;
          border-radius: 10px;

          .icon {
            width: 100%;
          }
        }
      }
    }
  }
}

.sidebar {
  display: flex;
  flex: 0 0 auto;
  width: 100%;
  max-width: global.$contextMenuCollapsedWidth;
  overflow: hidden;
  background-color: #f1f1f1;
  transition: max-width 0.25s ease;

  &.open {
    max-width: min(#{global.$contextMenuWidth}, 100vw - #{global.$toolbarWidth} - 20px);
  }

  .inner {
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    grid-area: sidebar;
    width: 100%;
    min-width: calc(#{global.$contextMenuWidth} / 2);
    padding: 20px;

    h6 {
      margin-bottom: 20px;
      padding: 8px;
    }

    .header {
      h6 {
        margin: 0;
      }
    }

    .items {
      .item {
        padding: 8px;

        .icon {
          margin: 0 12px 0 6px;
        }
      }
    }
  }
}

.toggleBar {
  flex: 0 0 40px;
  margin: 0 -20px;
  position: relative;
  cursor: pointer;

  .toggleSidebar {
    all: unset;
    position: absolute;
    left: 8px;
    top: 37.5px;
    width: 24px;
    height: 24px;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      content: '';
      width: 8px;
      height: 8px;
      border: 2px solid #666;
      border-top: 0;
      border-right: 0;
      margin-right: 3px;
      transform: rotate(-135deg);
      transition: transform 0.2s ease, margin-right 0.2s ease, border-color 0.2s ease;
    }

    &.isOpen {
      &::before {
        transform: rotate(45deg);
        margin-right: -3px;
      }
    }
  }

  &:hover {
    .toggleSidebar {
      &::before {
        border-color: global.$secondaryBackgroundColour;
      }
    }
  }
}
