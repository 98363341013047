@use '../../../public/style/global';

.formWrapper {
  max-width: 400px;
}

.profileWrapper {
  @include global.genericPadding();
}

.headWrapper {
  @include global.headWrapper();
}
