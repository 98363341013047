@use './public/style/global';

.baselineButton {
  @include global.genericButton();

  &.primary {
    @include global.genericButtonColour(
      global.$positiveButtonBackgroundColour,
      global.$positiveButtonBorderColour,
      global.$positiveButtonTextColour
    );
  }

  &.secondary {
    @include global.genericButtonColour(
      global.$negativeButtonBackgroundColour,
      global.$negativeButtonBorderColour,
      global.$negativeButtonTextColour
    );
  }

  &.link {
    @include global.genericButtonAsLink();
  }
}
