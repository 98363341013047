@use '../../../../public/style/global';

.workspacesUsers {
  @include global.genericPadding();

  .headWrapper {
    @include global.headWrapper();
  }

  td {
    vertical-align: middle !important;
  }
}
