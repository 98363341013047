/*
* This document is for styles that apply globally to all files
* These styles are not and should not be imported, however this
* file is included first in the header allowing all stylesheets to
* access any fonts here.
*
* Inlcude Overwrites/Globals in this file
* This file is loaded AFTER bootstrap!
*
* Q: Why does this file exist?
* A: If we define these :global styles in _global.scss webpack will create hundreds of redundant rules due
* to _global.scss being imported across hundreds of components. This file is imported once at the root app
* causing webpack to only generate these rules once!
*/


@use './fonts';
@use './typography';
@use './forms';
@use './global';


:global(html),
:global(body),
:global(#root) {
  height: 100%;
  min-height: 100%;
}

:global(body) {
  max-height: 100vh !important;
  overflow: hidden !important;
}

:global(#root) > div {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100%;
}

:global(.modal) {
  overflow: unset !important;

  :global(.modal-body) {
    max-height: 75vh !important;
    overflow: auto !important;
  }
}

// For Every "colour"
@each $colour, $hex in global.$bootstrapColours {
  // Edit the button style
  :global(.btn-#{$colour}) {
    color: contrast-color($hex, global.$darkFontColour, global.$lightFontColour) !important;
    background-color: $hex !important;
    border-color: $hex !important;

    &:hover,
    &:focus {
      background-color: darken($hex, 10%) !important;
      border-color: darken($hex, 10%) !important;
    }

    &:not(:disabled):active,
    &:active {
      background-color: darken($hex, 20%) !important;
      border-color: darken($hex, 20%) !important;
    }

    &:disabled {
      background-color: desaturate($hex, 20%) !important;
      border-color: desaturate($hex, 20%) !important;
      cursor: default;
    }
  }
}

// Font Resets

.h1,
h1 {
  @include global.h1;
}

.h2,
h2 {
  @include global.h2;
}

.h3,
h3 {
  @include global.h3;
}

.h4,
h4 {
  @include global.h4;
}

.h5,
h5 {
  @include global.h5;
}

.h6,
h6 {
  @include global.h6;
}

.callout {
  @include global.callout;
}

.subhead {
  @include global.subhead;
}

.caption {
  @include global.caption;
}

.label {
  @include global.label;
}

strong,
.bold {
  @include global.bold;
}

.medium {
  @include global.medium;
}

.regular {
  @include global.regular;
}

p {
  @include global.regular;
}

* {
  @include global.regular;
}

