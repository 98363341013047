/*
* Use this file to import fonts from various sources
* and create font faces. This file is appended to overwrite.module.scss
*/

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

@font-face {
  font-weight: normal;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
}
