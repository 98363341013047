/*
* These styles are appended to all files as SCSS extends content here
* This is also where we define Variables.
* Variables will not be visible once compiled however styles that are
* extended will be, Keep this in mind as it increases stylesheet size!
*
* DEFINING A STYLE IN THIS FILE WILL DEFINE IT (n) TIMES WITH (n) being
* THE TOTAL SHEETS IN THE PROJECT
*/

// SASS Helper functions
@use 'sass:color';

// This file is just more mixins
@import './hamburgers/hamburgers';

// @deprecated use '$contextMenuWidth' instead
$sidebarMaxWidth: 275px;
// @deprecated use '$toolbarWidth' instead
$sidebarIconWidth: 60px;
$contextMenuWidth: 300px;
$contextMenuCollapsedWidth: 20px;
$toolbarWidth: 60px;
$entitySidebarMaxWidth: 350px;
$contentMinWidth: 200px;
// How far should the sidebar collapse in (-$sidebarMaxWidth is completely hidden)
$sidebarCollapsedTransition: -$contextMenuWidth;
$entitySidebarCollapsedTransition: -$entitySidebarMaxWidth;
$baseTransition: 150ms;
$smallestScreen: 576px;
$upperContentHeight: 60px;
$navbarHeight: 60px;
$footerHeight: 32px;

$xs: 576px;
$sm: 768px;
$md: 992px;
$lg: 1200px;

$buttonBorderRadius: 5px;

@mixin fullsize {
  width: 100vw;
  height: 100vh;
}

@mixin genericPadding() {
  padding: 1rem 2rem;
}

// This is just a fancy way of doing text-overflow: ellipsis; with some vars
@mixin ellipsis($width, $break) {
  @media screen and (max-width: $break) {
    max-width: $width;
    overflow: hidden;
    text-overflow: ellipsis;
    @if $break <= $smallestScreen {
      max-width: 0 !important;
    }
  }
}

// Depending on Screen-size collapse and "..." to a certain size
@mixin collapseEllipsis {
  @include ellipsis(300px, 1200px);
  @include ellipsis(200px, 992px);
  @include ellipsis(100px, 768px);
  @include ellipsis(0, 576px);
  display: inline-block;
  max-width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 150ms linear;
}

// Supply a $color to check contrast and return either the $dark or $light colour.
$threshold: 55%;
@function contrast-color($color, $dark, $light) {
  @return if(lightness($color) < $threshold, $light, $dark);
}

// COLOURS
//// Bootstrap overwrites
//// See overwrite.module.scss for usage!
$bootstrapColours: (
  'primary': #007bff,
  'secondary': #6c757d,
  'success': #28a745,
  'danger': #dc3546,
  'warning': #ffc107,
  'info': #17a2b8,
);

//// Background Colours & Font Colours
/** @todo this is broken, we rely too much on primary background color which distorts the font colours below */
$lightFontColour: #000;
$darkFontColour: #fff;
$adjustBy: 2%;
$adjustByMultiplier: 2;

$primaryBackgroundColour: #31bba7;
$secondaryBackgroundColour: #000000;
$tertiaryBackgroundColour: #31bba7;

$primaryFontColour: contrast-color(
  $primaryBackgroundColour,
  $darkFontColour,
  $lightFontColour
);
$secondaryFontColour: contrast-color(
  $secondaryBackgroundColour,
  $darkFontColour,
  $lightFontColour
);
$tertiaryFontColour: contrast-color(
  $tertiaryBackgroundColour,
  $darkFontColour,
  $lightFontColour
);

////// Generate shades of those colours
$primaryBackgroundColourLight: lighten($primaryBackgroundColour, $adjustBy);
$primaryBackgroundColourLighter: lighten(
  $primaryBackgroundColour,
  $adjustBy * $adjustByMultiplier
);
$primaryBackgroundColourDark: darken($primaryBackgroundColour, $adjustBy);
$primaryBackgroundColourDarker: darken(
  $primaryBackgroundColour,
  $adjustBy * $adjustByMultiplier
);
$secondaryBackgroundColourLight: lighten($secondaryBackgroundColour, $adjustBy);
$secondaryBackgroundColourLighter: lighten(
  $secondaryBackgroundColour,
  $adjustBy * $adjustByMultiplier
);
$secondaryBackgroundColourDark: darken($secondaryBackgroundColour, $adjustBy);
$secondaryBackgroundColourDarker: darken(
  $secondaryBackgroundColour,
  $adjustBy * $adjustByMultiplier
);
$tertiaryBackgroundColourLight: lighten($tertiaryBackgroundColour, $adjustBy);
$tertiaryBackgroundColourLighter: lighten(
  $tertiaryBackgroundColour,
  $adjustBy * $adjustByMultiplier
);
$tertiaryBackgroundColourDark: darken($tertiaryBackgroundColour, $adjustBy);
$tertiaryBackgroundColourDarker: darken(
  $tertiaryBackgroundColour,
  $adjustBy * $adjustByMultiplier
);
$primaryFontColourLight: contrast-color(
  $primaryBackgroundColourLight,
  $darkFontColour,
  $lightFontColour
);
$primaryFontColourLighter: contrast-color(
  $primaryBackgroundColourLighter,
  $darkFontColour,
  $lightFontColour
);
$primaryFontColourDark: contrast-color(
  $primaryBackgroundColourDark,
  $darkFontColour,
  $lightFontColour
);
$primaryFontColourDarker: contrast-color(
  $primaryBackgroundColourDarker,
  $darkFontColour,
  $lightFontColour
);
$secondaryFontColourLight: contrast-color(
  $secondaryBackgroundColourLight,
  $darkFontColour,
  $lightFontColour
);
$secondaryFontColourLighter: contrast-color(
  $secondaryBackgroundColourLighter,
  $darkFontColour,
  $lightFontColour
);
$secondaryFontColourDark: contrast-color(
  $secondaryBackgroundColourDark,
  $darkFontColour,
  $lightFontColour
);
$secondaryFontColourDarker: contrast-color(
  $secondaryBackgroundColourDarker,
  $darkFontColour,
  $lightFontColour
);
$tertiaryFontColourLight: contrast-color(
  $tertiaryBackgroundColourLight,
  $darkFontColour,
  $lightFontColour
);
$tertiaryFontColourLighter: contrast-color(
  $tertiaryBackgroundColourLighter,
  $darkFontColour,
  $lightFontColour
);
$tertiaryFontColourDark: contrast-color(
  $tertiaryBackgroundColourDark,
  $darkFontColour,
  $lightFontColour
);
$tertiaryFontColourDarker: contrast-color(
  $tertiaryBackgroundColourDarker,
  $darkFontColour,
  $lightFontColour
);

// Sidebar
$subOpenMainWidth: 60px;
$avatarWidth: $subOpenMainWidth - 30px;
$sidebarHeaderHeight: 60px;
$sidebarItemHeight: 40px;
$routeHeight: 42px;
$sidebarLightestColour: #f4f5f7;
$sidebarLightenBy: 5%;
$primarySidebarColour: darken($sidebarLightestColour, $sidebarLightenBy);
$secondarySidebarColour: $sidebarLightestColour;
$primarySidebarSelected: $secondarySidebarColour;
$secondarySidebarSelected: $primarySidebarColour;
$primarySidebarFontColour: contrast-color($primarySidebarColour, #000, #fff);
$secondarySidebarFontColour: contrast-color(
  $secondarySidebarColour,
  #000,
  #fff
);
$primarySidebarSelectedFontColour: contrast-color(
  $primarySidebarSelected,
  #000,
  #fff
);
$secondarySidebarSelectedFontColour: contrast-color(
  $secondarySidebarSelected,
  #000,
  #fff
);

@mixin leftIcon($content) {
  width: 1rem;
  margin-right: 1rem;
  color: inherit;
  font-family: 'Font Awesome 5 Free', serif;
  text-align: center;
  content: $content;
}

// FONT STYLES

@mixin h1 {
  color: $primaryFontColour;
  font-weight: 700;
  font-size: 3.375rem;
  font-style: normal;
  line-height: 4.25rem;

  @media screen and (max-width: $sm) {
    font-size: 2.5rem;
    line-height: 3.25rem;
  }
}

@mixin h2 {
  color: $primaryFontColour;
  font-weight: 700;
  font-size: 2.75rem;
  font-style: normal;
  line-height: 3.25rem;

  @media screen and (max-width: $sm) {
    font-size: 2.25rem;
    line-height: 2.9375rem;
  }
}

@mixin h3 {
  color: $primaryFontColour;
  font-weight: 700;
  font-size: 2rem;
  font-style: normal;
  line-height: 2.625rem;

  @media screen and (max-width: $sm) {
    //font-size: 1.875rem;
    //line-height: 2.4375rem;

    font-size: 1.5rem;
    line-height: 1.9375rem;
  }
}

@mixin h4 {
  color: $primaryFontColour;
  font-weight: 700;
  font-size: 1.5rem;
  font-style: normal;
  line-height: 1.9375rem;
}

@mixin h5 {
  color: $primaryFontColour;
  font-weight: 700;
  font-size: 1.25rem;
  font-style: normal;
  line-height: 1.75rem;
}

@mixin h6 {
  color: $primaryFontColour;
  font-weight: 700;
  font-size: 1rem;
  font-style: normal;
  line-height: 1.625rem;
}

@mixin callout {
  color: $primaryFontColour;
  font-weight: 500;
  font-size: 0.875rem;
  font-style: normal;
  line-height: 1.125rem;
}

@mixin subhead {
  color: $primaryFontColour;
  font-weight: 500;
  font-size: 0.75rem;
  font-style: normal;
  line-height: 1.0625rem;
}

@mixin caption {
  color: $primaryFontColour;
  font-weight: 500;
  font-size: 0.625rem;
  font-style: normal;
  line-height: 0.875rem;
}

@mixin label {
  color: $primaryFontColour;
  font-weight: 500;
  font-size: 0.75rem;
  font-style: normal;
  line-height: 0.75rem;
  text-transform: uppercase;
}

// Font Mutation Classes

@mixin bold {
  color: $primaryFontColour;
  font-weight: 700;
  font-style: normal;
}

@mixin medium {
  color: $primaryFontColour;
  font-weight: 500;
}

@mixin regular {
  color: $primaryFontColour;
  font-weight: 400;
}

@mixin headWrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  margin: 1rem 0;

  @media screen and (min-width: $sm) {
    flex-direction: row;
    justify-content: space-between;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    display: block;
    flex: 1 0 100%;
    width: 100%;
    margin: unset;
    overflow: hidden;
    white-space: pre-line;
    text-align: center;
    text-overflow: ellipsis;

    @media screen and (min-width: $sm) {
      flex: 1 1 auto;
      width: unset;
      max-width: 75%;
      margin-top: 0;
      text-align: left;
    }
  }

  button {
    flex: 1 0 auto;
    margin-top: 1rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    @media screen and (min-width: $sm) {
      flex: 0 1 auto;
      width: unset;
      max-width: 25%;
      margin-top: 0;
    }
  }
}

// Buttons
$negativeButtonBorderColour: $primaryBackgroundColourDarker;
$negativeButtonBackgroundColour: $primaryBackgroundColour;
$negativeButtonTextColour: $primaryFontColour;

$positiveButtonBorderColour: $primaryBackgroundColourDarker;
$positiveButtonBackgroundColour: $primaryBackgroundColourDarker;
$positiveButtonTextColour: $primaryFontColour;

$defaultButtonBorderColour: $primaryBackgroundColourDarker;
$defaultButtonBackgroundColour: $primaryBackgroundColourDarker;
$defaultButtonTextColour: $primaryFontColour;

$linkButtonBorderColour: #fff0;
$linkButtonBackgroundColour: #fff0;
$linkButtonTextColour: $primaryFontColour;

$darkenOnHoverBy: 2.5%;

@mixin genericButton($radius: $buttonBorderRadius) {
  @include callout;
  @include bold;
  @include genericButtonColour();

  padding: 0.5rem 1rem;
  font-weight: 500;
  border-radius: $radius;
  transition: background-color $baseTransition linear;
}

@mixin genericButtonColour(
  $background: $defaultButtonBackgroundColour,
  $border: $defaultButtonBorderColour,
  $text: $defaultButtonTextColour
) {
  color: $text;
  background-color: $background;
  border: 2px solid $border;

  * {
    color: inherit;
  }

  &:disabled {
    color: color.scale($defaultButtonTextColour, $lightness: 60%);
    background-color: color.scale(
      $defaultButtonBackgroundColour,
      $lightness: 30%
    );
    border: 1px solid
      color.scale($defaultButtonBackgroundColour, $lightness: 25%);
    cursor: not-allowed;
  }
}

@mixin genericButtonAsLink {
  @include genericButtonColour(
    $linkButtonBorderColour,
    $linkButtonBackgroundColour,
    $linkButtonTextColour
  );

  &:disabled {
    background: $linkButtonBackgroundColour;
    border: $linkButtonBorderColour;
  }

  &:hover:not(:disabled) {
    text-decoration: underline;
    background: $linkButtonBackgroundColour;
  }
}
