@use '../../../public/style/global';

.headquartersUsers {
  @include global.genericPadding();

  .headWrapper {
    @include global.headWrapper();
  }

  .modalButton {
    display: flex;
  }
}
